import './App.css';
import './typography.css'
// import me from './src/assets/me.jpg'
import React, { useState, useEffect, useRef } from 'react';
import ProjectData from "./portfolio.json"
import Clock from './Clock';
import Cursor from './Cursor';


// TODO::::  style={{ overflow: isAnyOpen ? 'hidden' : 'auto' }}

function App() {
  // console.log('App component rendered');

  //function to download pdf
  const onButtonClick = () => {
    const pdfUrl = "Anna_freri_CV.pdf";
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "Anna_freri_CV.pdf"; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  //modes as states
  const [menuItem, setMenuItem] = useState('home');

  //change menu item state
  const toggleMenuItem = (newmenuItem) => {
    setMenuItem(newmenuItem);
  }
  // create ref variable
  const refWork = useRef(null);
  const refAbout = useRef(null);
  const refContact = useRef(null);
  const refReadmore = useRef(null);

  // Effect that scrolls to the ref based on the selected menu item
  useEffect(() => {
    if (menuItem === 'work') {
      refWork.current?.scrollIntoView({ behavior: 'smooth' });
    } else if (menuItem === 'about') {
      refAbout.current?.scrollIntoView({ behavior: 'smooth' });
    } else if (menuItem === 'contact') {
      refContact.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [menuItem]);


  //readmore: an array with bollean values, set to false by default. 
  const [isReadMoreClicked, setisReadMoreClicked] = useState(Array(ProjectData.length).fill(false));

  // console.log(isReadMoreClicked);

  const [openReadMoreIndex, setOpenReadMoreIndex] = useState(-1);

  // const toggleReadMore = (index) => {
  //   setOpenReadMoreIndex(openReadMoreIndex === index ? null : index);
  //   isReadMoreClicked[index] = !isReadMoreClicked[index];
  // };


  const toggleReadMore = (index) => {

    if (!isAnyOpen) {
      setOpenReadMoreIndex(openReadMoreIndex === index ? null : index);

      setisReadMoreClicked(prevState => {
        const updatedArray = [...prevState]; // Create a copy of the state array
        updatedArray[index] = !updatedArray[index]; // Toggle the value at the clicked index
        return updatedArray; // Return the updated array to update the state
      });
    }

  };



  //isAnyOpen detects if there is a true item in the array
  const isAnyOpen = isReadMoreClicked.includes(true);

  // console.log('drawer open: ' + isAnyOpen);


  //state that tracks if a user clicked or not
  const [userClicked, setUserClicked] = useState(false);

  // Function to close all read more sections
  const closeReadMore = () => {
    const newClickedArray = Array(ProjectData.length).fill(false);
    setisReadMoreClicked(newClickedArray);
  };

  // observe userCLicked state, and if its true and also a drawer is open, close all drawers
  // useEffect to handle mouse down events
  useEffect(() => {
    const handleMouseDown = () => {
      if (isAnyOpen && userClicked) {
        closeReadMore(); // Call the function to close all read more sections
      }
      setUserClicked(!userClicked);
    };

    document.body.addEventListener('mousedown', handleMouseDown);

    return () => {
      document.body.removeEventListener('mousedown', handleMouseDown);
    };
  }, [isAnyOpen, userClicked]);



  //when isAnyOpen is true, blur, darken and apply noscroll to the background
  useEffect(() => {
    const elementToBlur = document.getElementById('background'); // Get the element by ID
    if (isAnyOpen) {
      elementToBlur.classList.add('blur', 'darken'); // Add CSS classes
      document.body.style.overflow = 'hidden';
    } else {
      elementToBlur.classList.remove('blur', 'darken'); // Remove CSS classes
      document.body.style.overflow = 'unset';
    }
  }, [isAnyOpen]);




  return (
    <div className='container'>

      <Cursor isAnyOpen={isAnyOpen} />



      {isAnyOpen ?

        <button className='button-x' onClick={() => closeReadMore} >
          Close
        </button>
        :
        <div className='nav'>
          <button
            className={menuItem === 'work' ? 'button-active' : 'button'}
            onClick={() => { toggleMenuItem('work') }}
          >Work</button>
          <button className={menuItem === 'about' ? 'button-active' : 'button'} onClick={() => toggleMenuItem('about')} >About</button>
          <button className={menuItem === 'contact' ? 'button-active' : 'button'} onClick={() => toggleMenuItem('contact')} >Contact</button>
        </div>

      }


      {/* read more drawer*/}
      {ProjectData && ProjectData.map((project, index) => (
        <div key={project.id} >
          {project.longdescription && (
            <div
              ref={refReadmore}
              className={isReadMoreClicked[index] === true ? "readmore-open" : "readmore"}
            >
              <p className='titles' id='open-title'>{project.title}</p>

              <div className='long-descr-section'>
                <p className='open-subtitle' id='gray'>The Project</p>
                <p>{project.longdescription.intro}</p>
              </div>

              <div className='long-descr-section'>
                <p className='open-subtitle' id='gray'>The Approach</p>
                <p>{project.longdescription.middle}</p>
              </div>

              <div className='long-descr-section'>
                <p className='open-subtitle' id='gray'>The Result</p>
                <p>{project.longdescription.last}</p>
              </div>

              {project.longdescription.credits !== '' && (
                <div className='long-descr-section'>
                  <p className='open-subtitle' id='gray'>Credits</p>
                  <p>{project.longdescription.credits}</p>
                </div>
              )}

              {project.longdescription.awards !== '' && (
                <div className='long-descr-section'>
                  <p className='open-subtitle' id='gray'>Awards</p>
                  <p>{project.longdescription.awards}</p>
                </div>
              )}




              {/* Render all images */}

              {project.images && (
                <div className='images-container'>

                  {/* Render Vimeo iframe */}

                  {project.vimeoUrl && (

                    <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
                      <iframe
                        src={project.vimeoUrl}
                        style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%" }}
                        frameBorder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </div>

                  )}

                  {project.video && (
                    <video
                      src={project.video}
                      autoPlay
                      loading="lazy"
                      loop
                      muted
                      playsInline
                    >
                    </video>
                  )}



                  {project.images.map((image, imgIndex) => (
                    <img className='images' key={imgIndex} src={image} alt={`Image ${imgIndex}`} />
                  ))}
                </div>
              )}

            </div>
          )}
        </div>
      ))}





      <div className='main' id='background'>

        <header>
          <p>Work Archive 2024</p>
          <p id='pdf' onClick={onButtonClick}> Download CV</p>
          <p>Mendrisio, CH</p>
          <div><Clock /></div>
        </header>

        <div className='intro' >

          <h2>
            Hello! I'm Anna Freri <span>(🧑🏻‍🦲)</span> a multidisciplinary designer & coding enthusiast. With a ten year experience <span>(👨🏻‍💻)</span> in various disciplines and a focus on digital design, I approach each project with curiosity, playfulness and a holistic view (👀) on interaction. This is an archive of work.
          </h2>
          {/* <img src={process.env.PUBLIC_URL + '/me.jpg'} alt="My Image" /> */}
          {/* <div className='name'>
            <h1>Anna</h1>
            <h1>Freri</h1>

          </div> */}

          {/* <div className='under-name' >
          <h3>Highly driven, multidisciplinary designer, and coding enthusiast with solid experience in various disciplines.</h3>
          <h3>A focus on digital design, and a holistic approach to interaction. This is an archive of work.</h3>
          </div> */}
        </div>

        <div
          ref={refWork}
          id='work'
          style={{
            transform: isAnyOpen ? 'translate(-10%, 0px)' : 'translate(0px, 0px)',
            transition: '0.5s'
          }}
        >

          <h1>Selected Work</h1>
          <div className="projects">
            <div className='filters'>
              <p id="filter_title">Disciplines</p>

              <p>UI/UX Design</p>
              <p>Coding</p>
              <p>Digital fabrication</p>
              <p>Concept Design</p>
              <p>Art direction</p>
              <p>Illustration</p>

            </div>
            <div className='project_grid'>
              {ProjectData && ProjectData.map((project, index) => (
                <div className='project' key={project.id} >
                  <div className='main_info'>

                    <h3 className='bold'>{project.title}</h3>
                    <h3 className='bold' id="subtitle">{project.subtitle}</h3>
                    <button id='read-more' onClick={() => toggleReadMore(index)}>Read more</button>

                    <div className='tags'>
                      {project.categories && project.categories.map(category => (
                        <div className='tag'>{category}</div>
                      ))}
                    </div>

                  </div>

                  <div className='feat_image'>
                    {project.mainimage !== '' ? (
                      <img
                        src={project.mainimage}
                        alt={project.title}
                        onClick={() => toggleReadMore(index)}
                        loading="lazy"
                      />
                    ) : (
                      <video
                        autoPlay={isAnyOpen ? false : true}// Autoplay when isAnyOpen is false
                        loading="lazy"
                        loop
                        muted
                        playsInline
                        onClick={() => !isAnyOpen && toggleReadMore(index)}
                      >
                        <source src={project.mainvideo} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div ref={refAbout} className='cv'>
          <h1>Curriculum Vitae</h1>
          <div className='section'>
            <h3 id="subtitle" className='bold'>Work</h3>
            <h3 className='bold'>Soul Creative Agency</h3>
            <div id='work_details' >
              <div id='work_details_row'>
                <p >2021-2023</p>
                <p>Digital design Lead</p>
              </div>
              <div id='work_details_row'>
                <p >2016-2020</p>
                <p id="col_lg" >UI&UX designer</p>
              </div>
              <div id='work_details_row'>
                <p >2014-2015</p>
                <p id="col_lg" >Junior designer</p>
              </div>

              <div id='work_details_row'>
                <p >What did I do there?</p>
                <p id="col_lg" >Throughout the years, in addition to my main focus on UI & UX design, I have been actively involved in a variety of tasks and procedures such as copywriting, naming, graphic design, packaging, storytelling, visual identity design, and project management. This sparked my curiosity and helped me develop a 360-degree understanding of what interaction and communication mean, providing me with a direct overview from the ideation stage to production.</p>
              </div>

              <div id='work_details_row'>
                <p>As a design lead</p>
                <p>Since 2021, my day-to-day tasks have included designing interfaces and user journeys for various websites and apps, as well as leading the digital team from the wireframe stage to final deployment to create exceptional products and engaging experiences.</p>
              </div>



            </div>

          </div>

          <div className='section'>
            <h3 id="subtitle" className='bold'></h3>
            <h3 id="col_sm" className='bold' >KORRES Uni Lab</h3>
            <div id='work_details' >
              <div id='work_details_row'>
                <p >2013</p>
                <p>Graphic Designer</p>
              </div>
              <div id='work_details_row'>
                <p >What did I do there?</p>
                <p>I was handpicked by KORRES to join an all-student team of diverse disciplines. Together, we collaborated to create a product for the KORRES skincare line. This unique opportunity allowed me to contribute my expertise in graphic & packaging design, while also learning from and collaborating with fellow students from various backgrounds.</p>
              </div>
            </div>
          </div>

          <div className='section'>

            <h3 id="subtitle" className='bold'>Education</h3>
            <h3 className='bold'>Supsi, CH</h3>
            <div id='work_details' >
              <div id='work_details_row'>
                <p id="col_sm">2023-2024</p>
                <p id="col_sm">Master of Arts in Interaction Design</p>
              </div>
            </div>

          </div>


          <div className='section'>
            <h3></h3>
            <h3 className='bold'  >University of West Attica, GR</h3>
            <div id='work_details_row'>

              <p id="col_sm">2009-2012</p>
              <p id="col_sm">Bachelor of Arts in Graphic design & Visual Communication</p>
            </div>
          </div>

          <div className='section-_2_col'>
            <h3 id="subtitle" className='bold'>Languages</h3>

            <div id='work_details' >
              <div id='work_details_row'>
                <p id="col_sm">English</p>
                <p id="col_sm">Proficiency (C2)</p>
              </div>
              <div id='work_details_row'>
                <p id="col_sm">German</p>
                <p id="col_sm">Mittelstufe (C1)</p>
              </div>
              <div id='work_details_row'>
                <p id="col_sm">Greek</p>
                <p id="col_sm">Native</p>
              </div>
            </div>



          </div>
          <div className='section-_2_col'>
            <h3 id="subtitle" className='bold'>About me</h3>
            <p id="col_lg">I am currently enrolled in the Master of Interaction Design at SUPSI, CH. Over my ten years of work experience, I've been employed in a small agency where I often found myself juggling various roles and tasks simultaneously. This environment provided me with the freedom to explore different disciplines and crafts, leading me to realize that versatility is what I find truly fulfilling and ultimately rewarding. Above all, I consider myself a maker and problem solver. While tasks and procedures may vary, they all serve the same purpose: telling engaging stories and creating useful and simply great products that users can relate to and be excited about. I firmly believe that understanding the problem and possessing the problem-solving skills required to arrive at a solution are the key elements in designing exceptional experiences and products, regardless of the medium.</p>
          </div>
        </div>

        <div ref={refContact} className='contact'>
          <h1>Don't be a stranger</h1>
          <div className='section_contact'>
            <div id="col_contact" >
              <h3 id="col_sm" >More info</h3>
            </div>
            <div id="col_contact" >
              <h3>Instagram archives</h3>
              <a target="_blank" href='https://www.instagram.com/fish.wish/'>Illustrations</a>
              <a target="_blank" href='https://www.instagram.com/existentialism.but.with.code'>Coding</a>
              <a target="_blank" href='https://www.instagram.com/thisisme.ceramics/'>Ceramics</a>

            </div>
            <div id="col_contact" >
              <h3>Get in touch</h3>
              <a target="_blank" href='https://www.linkedin.com/in/anna-freri/'>Linkedin</a>
              <a href='mailto:annafreri@yahoo.gr'>Email</a>
            </div>

          </div>

          <div className='section_contact'>
            <div id="col_contact" >
              <h3 id="col_sm" >
                Mendrisio, CH
              </h3>
            </div>
            <div id="col_contact" >
              <h3>Design + Code</h3>
              <a target="_blank" href='https://www.linkedin.com/in/anna-freri/'>Anna Freri</a>
            </div>
            <div id="col_contact" >
              <h3>Download CV</h3>
              {/* <button onClick={onButtonClick}>
                    Download PDF
                </button> */}
              <a target="_blank" onClick={onButtonClick}>annafreri_cv.pdf</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;









