// Clock.js
import React, { useState, useEffect } from 'react';

function Clock() {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId); // Clean up the interval on unmount
  }, []); // Empty dependency array ensures the effect runs only once

  return (
    
      <p>{time.toLocaleTimeString('en-CH', {timeZone: 'Europe/Zurich'})}</p>
    
  );
}

export default Clock;
